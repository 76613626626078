export function getRandomInt(min: number, max: number) {
    min = Math.ceil(min)
    max = Math.floor(max)
    return Math.floor(Math.random() * (max - min) + min) // The maximum is exclusive and the minimum is inclusive
}

export function round(num: number, decimals = 2) {
    const re = new RegExp(`^-?\\d+(?:\.\\d{0,${decimals || -1}})?`)
    return parseFloat(num.toString().match(re)?.[0] || '0')
}

export function formatNumber(number: number, digits = 2) {
    return round(number, digits).toLocaleString('en-US', {
        minimumFractionDigits: digits,
    }).replace(/,/g, ' ')
}

export const aids = []